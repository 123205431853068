html {
  font-size           : 10px;
}

html,
body {
  padding             : 0;
  margin              : 0;
}

html,
body,
#root,
.App,
.fullHeight {
  height              : 100%;
  // overflow: auto;
}

*, ::before, ::after {
  -webkit-box-sizing  : border-box;
  -moz-box-sizing     : border-box;
  box-sizing          : border-box;
}

body {
  font-family         : 'Open Sans', sans-serif;
  font-size           : 1.6rem;
  font-weight         : 400;
  line-height         : 1.6;
  color               : #333;
  background-color    : #fff;
  text-rendering      : optimizeLegibility;
}

h1, h2, h3, h4, h5, h6 {
  font-family         : 'Montserrat', sans-serif;
  font-weight         : 700;
  line-height         : 1.2;
  margin-top          : 2.4rem;
  margin-bottom       : 1.2rem;
  color               : #333;
}

h1 {
  font-size           : 3.6rem;
}

h2 {
  font-size           : 2.8rem;
}

h3 {
  font-size           : 2.2rem;
}

h4 {
  font-size           : 1.8rem;
}

h5 {
  font-size           : 1.6rem;
}

h6 {
  font-size           : 1.4rem;
}

p, ul, ol, pre {
  margin-top          : 1.2rem;
  margin-bottom       : 1.2rem;
}

a {
  color               : #333;
  text-decoration     : none;
  transition          : all 0.2s ease-in-out;
}

a:hover {
  color               : #60b246;
}

img {
  max-width           : 100%;
  height              : auto;
  vertical-align      : middle;
}

button,
input[type='submit'],
input[type='reset'],
input[type='button'] {
  border-radius       : 4px;
  border              : none;
  padding             : 1.2rem 2.4rem;
  font-family         : 'Montserrat', sans-serif;
  font-size           : 1.6rem;
  font-weight         : 700;
  text-transform      : uppercase;
  letter-spacing      : 1px;
  transition          : all 0.2s ease-in-out;
  cursor              : pointer;
}

button:hover,
input[type='submit']:hover,
input[type='reset']:hover,
input[type='button']:hover {
  background-color    : #60b246;
  color               : #fff;
}

button:active,
input
/* Add a margin to the body element */
body {
  margin: 20px;
}

/* Add styles for headings */
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  color: #333;
}

/* Add styles for links */
a {
  color: blue;
  text-decoration: none;
}

a:hover {
  color: navy;
  text-decoration: underline;
}

/* Add styles for tables */
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #ddd;
}

/* Add styles for forms */
input[type="text"], input[type="email"], input[type="password"] {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #ddd;
}

input[type="submit"] {
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: navy;
}



// html {
//     font-size           : 10px;
// }

// html,
// body {
//     padding             : 0;
//     margin              : 0;
// }

// html,
// body,
// #root,
// .App,
// .fullHeight {
//     height              : 100%;
//     // overflow: auto;
// }

// *, ::before, ::after {
//     -webkit-box-sizing  : border-box;
//     -moz-box-sizing     : border-box;
//     box-sizing          : border-box;
// }

// body {
//     font-family         : 'Montserrat', sans-serif;
//     font-size           : 1.8rem;
//     font-weight         : 400;
//     line-height         : 1;
// }

// h1 {
//     display             : block;
//     width               : 100%;
//     padding             : 0;
//     margin              : 0 auto 2rem;
//     font-size           : 3.0rem;
//     line-height         : 3.2rem;
//     color               : #000000;
//     text-align          : left;
//     font-weight         : 400;
//     text-transform      : uppercase;
//     border-bottom       : #d3d3d3;
// }

// h2 {
//     display             : block;
//     width               : 100%;
//     padding             : 0;
//     margin              : 0 auto 2rem;
//     font-size           : 2.5rem;
//     line-height         : 2.7rem;
//     color               : #000000;
//     text-align          : left;
//     font-weight         : 400;
//     text-transform      : uppercase;
//     border-bottom       : #d3d3d3;
//   }

//   h3 {
//     display             : block;
//     width               : 100%;
//     padding             : 0;
//     margin              : 0 auto 2rem;
//     font-size           : 2.0rem;
//     line-height         : 2.2rem;
//     color               : #000000;
//     text-align          : left;
//     font-weight         : 400;
//     text-transform      : uppercase;
//     border-bottom       : #d3d3d3;
//   }

// a {
//     text-decoration     : none;
//     cursor              : pointer;
//     // padding             : 8px 10px;
// }

// a, a:hover {
//     -webkit-transition  : all .4s ease-in-out;
//     -moz-transition     : all .4s ease-in-out;
//     transition          : all .4s ease-in-out;
// }

// .main {
//     display             : block;
//     overflow            : auto;
//     width               : 100%;
//     max-width           : 1450px;
//     padding             : 0 10px;
//     margin              : 0 auto;
// }

// .directoryLayout {
//   height              : calc(100% - 6.5rem);
//   display             : block;
//   // overflow            : auto;
//   width               : 100%;
//   max-width           : 1450px;
//   // padding             : 0 10px;
//   margin              : 0 auto;
// }

// .adminLayout {
//     height              : calc(100% - 6.5rem - 5.8rem);
// }

// .dashboardLayout {
//     height              : 100%;
// }

// .controlPanel {
//     position            : relative;
//     display             : inline-block;
//     width               : 100%;
//     min-height          : 100%;
//     padding             : 3rem 0 6rem 25.0rem;
//     border-bottom       : 1px solid #d3d3d3;

//   .sidebar {
//     position            : absolute;
//     top                 : 0;
//     left                : 10px;
//     width               : 25.0rem;
//     height              : 100%;
//     z-index             : 1;
//     border-right        : 1px solid #d3d3d3;
//   }

//   .content {
//     padding             : 0 10px;
//   }
// }

// @media only screen and (max-width: 980px) {
//     html {
//       font-size       : 9px;
//     }
  
//     .controlPanel {
//       padding         : 0 10px;
  
//       .sidebar {
//         position      : relative;
//         width         : 100%;
//         border        : none;
//         margin-bottom : 2.5rem;
//       }
//     }
//   }